// Agent & Lender Signup/Profile Pages
export const addFile = (formData) => {
  return fetch("/api/uploads/create", {
    method: "POST",
    body: formData,
  });
};
export const postUpload = (uploadData) => {
  return fetch("/api/uploads", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(uploadData),
  });
};
// Agent Signup
export const addAgent = (agentData) => {
  return fetch("/api/agents", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(agentData),
  });
};
export const createUser = (userData) => {
  return fetch("/api/users", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
};
export const postNotice = (noticeData) => {
  return fetch("/api/notices", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(noticeData),
  });
};
export const sendWelcome = (welcomeData) => {
  return fetch("/api/email/send-welcome-email", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(welcomeData),
  });
};
export const sendConfirmation = (confirmationData) => {
  return fetch("/api/email/send-confirmation-email", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(confirmationData),
  });
};
// Agent Profile
export const getAgentProfile = (agent_id) => {
  return fetch(`/api/agents/${agent_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updateUser = (userData, user_id) => {
  return fetch(`/api/users/${user_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
};
export const getUserProfile = (user_id) => {
  return fetch(`/api/users/${user_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// Edit Agent Profile
export const updateAgent = (agentData, agent_id) => {
  return fetch(`/api/agents/${agent_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(agentData),
  });
};
export const deleteAgent = (agent_id) => {
  return fetch(`/api/agents/${agent_id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// Login Page
export const loginUser = (userData) => {
  return fetch("/api/users/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
};
// Agent Search Page
export const getPremiumAgents = () => {
  return fetch(`/api/agents/premium`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getAgentByCity = (city) => {
  return fetch(`/api/agents/city/${city}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getAgentByName = (name) => {
  return fetch(`/api/agents/name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getAgentByEmail = (email) => {
  return fetch(`/api/agents/email/${email}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getCityNames = (city) => {
  return fetch(`/api/agents/city-searchbar/${city}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getRecentAgents = () => {
  return fetch(`/api/agents/recently-added/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getAgentNames = (name) => {
  return fetch(`/api/agents/name-searchbar/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getAgentFullNames = (name) => {
  return fetch(`/api/agents/full-name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getAgentByCityAndName = (city, name) => {
  return fetch(`/api/agents/city/${city}/name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getAgentByCityAndFullName = (city, name) => {
  return fetch(`/api/agents/city/${city}/full-name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// Busca Agent Search Page
export const getBuscaPremiumAgents = () => {
  return fetch(`/api/agents/busca/premium`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaAgentByCity = (city) => {
  return fetch(`/api/agents/busca/city/${city}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaAgentByName = (name) => {
  return fetch(`/api/agents/busca/name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaAgentByEmail = (email) => {
  return fetch(`/api/agents/busca/email/${email}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaRecentAgents = () => {
  return fetch(`/api/agents/busca/recently-added/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaCityNames = (city) => {
  return fetch(`/api/agents/busca/city-searchbar/${city}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaAgentNames = (name) => {
  return fetch(`/api/agents/busca/name-searchbar/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaAgentFullNames = (name) => {
  return fetch(`/api/agents/busca/full-name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaAgentByCityAndName = (city, name) => {
  return fetch(`/api/agents/busca/city/${city}/name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaAgentByCityAndFullName = (city, name) => {
  return fetch(`/api/agents/busca/city/${city}/full-name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// Super User
export const getFindahomeNotices = () => {
  return fetch(`/api/notices/findahome`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaNotices = () => {
  return fetch(`/api/notices/busca`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const removeNotice = (notice_id) => {
  return fetch(`/api/notices/${notice_id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getCSVFile = () => {
  return fetch(`/api/csv`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getLendersCSVFile = () => {
  return fetch(`/api/csv/lenders`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// Password Reset
export const forgotPassword = async (payload) => {
  return fetch("/api/users/forgot-password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

export const updatePassword = ({ email, otp, newPassword }) => {
  console.log({ email, otp, newPassword });
  return fetch("/api/users/update-password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      otp,
      newPassword,
    }),
  });
};
export const validateOTP = async (payload) => {
  return fetch("/api/users/check-otp", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
// Lender Signup
export const addLender = (lenderData) => {
  return fetch("/api/lenders", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(lenderData),
  });
};
// Lender Profile
export const getLenderProfile = (lender_id) => {
  return fetch(`/api/lenders/${lender_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// Edit Lender Profile
export const updateLender = (lenderData, lender_id) => {
  return fetch(`/api/lenders/${lender_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(lenderData),
  });
};
export const deleteLender = (lender_id) => {
  return fetch(`/api/lenders/${lender_id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// Lender Search Page
export const getPremiumLenders = () => {
  return fetch(`/api/lenders/premium`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getLenderByCity = (city) => {
  return fetch(`/api/lenders/city/${city}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getLenderByName = (name) => {
  return fetch(`/api/lenders/name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getLenderByEmail = (email) => {
  return fetch(`/api/lenders/email/${email}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getLenderCityNames = (city) => {
  return fetch(`/api/lenders/city-searchbar/${city}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getRecentlenders = () => {
  return fetch(`/api/lenders/recently-added/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getLenderNames = (name) => {
  return fetch(`/api/lenders/name-searchbar/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getLenderFullNames = (name) => {
  return fetch(`/api/lenders/full-name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getLenderByCityAndName = (city, name) => {
  return fetch(`/api/lenders/city/${city}/name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getLenderByCityAndFullName = (city, name) => {
  return fetch(`/api/lenders/city/${city}/full-name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// Busca Lender Search Page
export const getBuscaPremiumLenders = () => {
  return fetch(`/api/lenders/busca/premium`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaLenderByCity = (city) => {
  return fetch(`/api/lenders/busca/city/${city}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaLenderByName = (name) => {
  return fetch(`/api/lenders/busca/name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaLenderByEmail = (email) => {
  return fetch(`/api/lenders/busca/email/${email}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaRecentLenders = () => {
  return fetch(`/api/lenders/busca/recently-added/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaLenderCityNames = (city) => {
  return fetch(`/api/lenders/busca/city-searchbar/${city}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaLenderNames = (name) => {
  return fetch(`/api/lenders/busca/name-searchbar/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaLenderFullNames = (name) => {
  return fetch(`/api/lenders/busca/full-name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaLenderByCityAndName = (city, name) => {
  return fetch(`/api/lenders/busca/city/${city}/name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaLenderByCityAndFullName = (city, name) => {
  return fetch(`/api/lenders/busca/city/${city}/full-name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
